export class Atencionesmedicas{
	id?:number;
	horainicio?:Date;
	anotaciones?:string;
	estado?:number;
	created?:Date;
	modified?:Date;
	citasmedica_id?:number;
	topoatencion?:number;
	medico?:number;
}

export class BuscarGeneral{
	buscar_cie10?:string;
	buscar_diagnostico?:string;
}

export class CitaExemanesMedicos{
	id?:number;
	fechaexamen?:Date;
	tipo?:number;
	costo?:any;
	pendiente?:any;
	cancelado?:any;
	horaatencion?:any;
	examenesmedico_id?:number;
	horarioempleado_id?:number;
	solicitudexterna_id?:number;
	persona_id?:number;
	registrado?:number;
	laboratorista?:number;
	estado?:number;
	comprobante?:number;
	created?:Date;
	modified?:Date;
	fechaentrega?:Date;
	resultadoexemenes?:ResultadoExemenesMedico[];
}

export class ResultadoExemenesMedico{
	id?:number;
	resultado?:string;
	parametrosexamene_id?:number;
	citaexemane_id?:number;
	estado?:number;
	created?:Date;
	modified?:Date;
}